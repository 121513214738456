:root {
    --main: #024289;
    --darkgray: #333;
}

.footerBar{
    background-color:var(--darkgray);
    padding:25px 0;
    position:relative;
    margin-top: 50px;
}

.footerBar *{
    color:white;
}


@media screen and (max-width:600px){
    .footerBar{
       margin-bottom: 100px;
    }
}