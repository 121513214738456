body {
    background-color: white!important;
}

body, h1, h2, h3, h4, h5, h6, p, a, li {
    font-family: 'IBM Plex Serif', serif!important;
}

#root {
    overflow: visible!important;
}

a {
    color: #024289;
}

.btnLink, .btnLink:link, .btnLink:visited, .btnLink:hover{
    color:rgb(255, 255, 255)!important;
}

a:hover {
    color: #1C5494;
    text-decoration: none!important;
}

.btnLink:hover{
    color:rgb(255, 255, 255)!important;
}

.mainHome {
    padding: 100px 0;
}

.sidebar {
    background: #024289;
    border-radius: 5px;
}

.companyImg {
    display: flex;
    justify-content: center;
}

.companyImg img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    border: 10px solid rgba(255, 255, 255, 0.1);
    background-color: white;
}

.sidebar * {
    color: white;
}

.MuiGrid-root.bottomSibebar.MuiGrid-item.MuiGrid-grid-sm-12.MuiGrid-grid-lg-3 {
    padding: 0!important;
}

.menuContainer {
    display: flex;
    flex-direction: column;
}

.menuContainer button {
    min-height: 150px;
    width: 100%;
}

.productTitle {
    padding: 15px!important;
    font-size: 2.5rem;
    font-weight: bold;
    padding: 0!important;
}

.menuContainer {
    margin-bottom: 50px;
}

.phasestable {
    margin-top: 0!important;
}

.phasestable th, .phasestable td {
    padding: 10px 0;
}

.lineContainer {
    width: 100%;
    justify-content: center;
    display: flex;
}

.traciabilityCard:last-child #svgLine {
    display: none;
}

.attributeTitle {
    display: none;
    font-weight: bold;
    margin-top: 0;
    margin-bottom: 20px;
}

.additionalImg {
    margin: 20px 0;
}

.additionalImg img {
    border: 1px solid white;
}

.parallaxContainer {
    -webkit-box-shadow: inset 0 0 10px 3px white;
    box-shadow: inset 0 0 10px 3px white;
}

.react-reveal {
    max-width: 100%;
    width: 100%;
}

@media screen and (max-width:959px) {
    .sidebar {
        display: none;
    }
}

@media screen and (max-width:600px) {
    body {
        overflow-x: hidden;
        width: 100%;
    }
    .sidebar {
        display: none;
    }
    .tabContent {
        width: 100%;
        max-width: 100%;
        overflow-x: hidden;
    }
    .mainHome {
        padding: 30px 0px!important;
        margin-top: 15px;
    }
    .attributes {
        padding: 0 20px!important;
    }
    .productTitle, .productSubtitle {
        text-align: left;
    }
    .attributeTitle {
        display: block;
    }
    .react-reveal {
        max-width: 100%;
        width: 100%;
    }
    .tabContent .react-reveal:last-child .lineContainer {
        display: none;
    }
}